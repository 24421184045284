import {
  FETCH_CIAM_REQUEST_ERROR,
  SPRINGFIELD_AUTH_LOGIN_ENDPOINT_DEV,
  SPRINGFIELD_AUTH_LOGIN_ENDPOINT_PROD,
} from "./use-auth.constants";
import { FetchCIAMURLService } from "./use-auth.types";
import { parseCIAMURL } from "./use-auth.utils";

export const fetchCIAMURL: FetchCIAMURLService = async ({ redirectUrl }) => {
  try {
    const isDevOrPrEnv = process.env.TARGET_ENV === "pr" || process.env.TARGET_ENV === "dev";

    const AUTH_LOGIN_ENDPOINT = isDevOrPrEnv
      ? SPRINGFIELD_AUTH_LOGIN_ENDPOINT_DEV
      : SPRINGFIELD_AUTH_LOGIN_ENDPOINT_PROD;

    const payload = await fetch(AUTH_LOGIN_ENDPOINT, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        redirecturl: redirectUrl,
      }),
    });

    if (!payload.ok) {
      throw FETCH_CIAM_REQUEST_ERROR;
    }

    const text = await payload.text();

    return parseCIAMURL(text);
  } catch (error) {
    throw FETCH_CIAM_REQUEST_ERROR;
  }
};
