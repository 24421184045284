import { useCallback, useEffect, useState } from "react";
import { logError } from "@telia/b2x-logging";
import { springfieldLogin } from "@telia/b2x-rest-client";

import { FETCH_CIAM_REQUEST_ERROR, FETCH_IS_LOGGED_IN_STATUS_ERROR } from "./use-auth.constants";
import { fetchCIAMURL } from "./use-auth.services";

export const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const checkLoggedInStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const loggedInStatus = await springfieldLogin.LoginStatusService.isLoggedInUsingGet();
      setIsLoggedIn(loggedInStatus);
      setIsLoading(false);
    } catch (error) {
      logError("Check logged in status", FETCH_IS_LOGGED_IN_STATUS_ERROR);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    checkLoggedInStatus();
  }, [checkLoggedInStatus]);

  const handleAuth = useCallback(async () => {
    try {
      if (!isLoggedIn && typeof window !== "undefined") {
        const loginUrl = await fetchCIAMURL({ redirectUrl: window.location.pathname ?? "" });
        window.location.assign(loginUrl);
      }
    } catch (error) {
      const errorMessage =
        error === FETCH_CIAM_REQUEST_ERROR
          ? FETCH_CIAM_REQUEST_ERROR
          : FETCH_IS_LOGGED_IN_STATUS_ERROR;

      logError("Log in", errorMessage);
    }
  }, [isLoggedIn]);

  return { isLoggedIn, handleAuth, isLoading };
};
