import { useLayoutEffect, useState } from "react";

export const SCREEN_MEDIA_QUERY = {
  SM: `(max-width: 37.5em)`,
} as const;

// Use only in client-side
export const useScreenSize = (): { isSmall: boolean } => {
  const [isSmall, setIsSmall] = useState(false);

  useLayoutEffect(() => {
    const smallScreen = window.matchMedia(SCREEN_MEDIA_QUERY.SM);
    function updateSize() {
      if (smallScreen.matches) {
        setIsSmall(true);
      } else {
        setIsSmall(false);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return { isSmall };
};
